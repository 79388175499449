@import "../variables.scss";

.container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media screen and (min-width: $desktopMaxBreakpoint) {
    flex-direction: row;
  }
}

.gfeDobInput {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  height: 50px;
  min-height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 1px 15px;
  font-size: 16px;
  font-weight: 400;
  color: #000;

  &.smallSize {
    height: 38px;
    min-height: 38px;
    border-radius: 4px;
  }

  @media screen and (min-width: $desktopMaxBreakpoint) {
    max-width: 380px;
  }

  &.error {
    border-color: #bf2828;
    background-color: #fff8f8;
  }
}

.inputContainer {
  display: flex;
  gap: 10px;
}

.label {
  display: inline-block;
  font-size: 16px;
  color: #a4abb2;
  width: 65px;
  max-width: 65px;
  min-width: 65px;
}

.gfeStateSelect {
  width: 100%;

  @media screen and (min-width: $desktopMaxBreakpoint) {
    width: 380px;
  }
}

.dobCalendar {
  > div:first-child {
    max-width: 33px;
    min-width: 33px;
    margin-bottom: 0;
  }
}

.calendarRight {
  top: 38px !important;
  left: auto !important;
  right: 0;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
}
